import React from 'react'
import { useStyles } from './style'
import { Grid } from '@material-ui/core'

export default function AppFooter () {
  const classes = useStyles()
  return (
    <Grid className={classes.footerCtn}>
      <Grid className={classes.footerLinks}>
      <a href="/Terms"> Terms </a>
        <a href="/Privacy"> Privacy </a>
        <a href="/Status"> Status </a>
        <a href="/Blog"> Blog </a>
        <a href="/Help"> Help </a>
      </Grid>
        
    </Grid>
  )
}
