import React from 'react'
import { Routes } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  Clients,
  TimePage
} from './pages'

const ApplicationRoutes = () => {
  return (
    <Routes>
      <PrivateRoute path='/' element={<TimePage />} />
      <PrivateRoute path='/clients' element={<Clients />} />
    </Routes>
  )
}

export default ApplicationRoutes
