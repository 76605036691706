import React, { useState } from 'react'
import { useStyles } from './style'
import { Button, Grid, Modal } from '@material-ui/core'
import Select from 'react-select'

export default function SearchInput ({ options, showGroupLabelOnButton, showDefault, topPosition, btnHeight }) {
  const [openProjectBtnModal, setOpenProjectBtnModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const handleOpenProjectBtnModal = () => {
    setOpenProjectBtnModal(true)
  }
  const handleCloseProjectBtnModal = () => {
    setOpenProjectBtnModal(false)
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
  const label = {
    fontSize: 14,
    fontWeight: 'bolder',
    textTransform: 'none',
    color: 'black'
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      marginLeft: state.selectProps.menuIsOpen ? '15px' : '0',
      backgroundColor: state.isFocused ? 'black' : 'transparent',
      color: state.isFocused ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'black',
        color: 'white'
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: () => ({
      boxShadow: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      width: '98%',
      minHeight: '15px',
      display: 'flex',
      height: '30px',
      margin: '7px auto',
      color: 'black',
      border: '1px solid black',
      alignContent: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        border: '1px solid black'
      }
    }),
    menuList: (provided) => ({
      ...provided,
      overflowX: 'hidden'
    })
  }
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={label}>{data.label}</span>
    </div>
  )
  const classes = useStyles()
  return (
    <>
      <Button
        onClick={handleOpenProjectBtnModal}
        className={`${classes.projectSelectBtn} ${openProjectBtnModal ? classes.modalOpenBackground : ''}`}
        styles={{ height: btnHeight }}
      >
        {selectedOption ? (
          <span className={classes.chosedSelectionCtn}>
            {showGroupLabelOnButton && (
              <span className={classes.chosedSelectionGroupTitle}>{selectedOption.group}</span>
            )}
            <span className={classes.chosedSelectionProjectTitle}>
              {selectedOption.label.length > 70 ? `${selectedOption.label.slice(0, 70)}...` : selectedOption.label}
            </span>
          </span>
        ) : (
          <span className={classes.chosedSelectionCtn}>
            {showGroupLabelOnButton && (
              <span className={classes.chosedSelectionGroupTitle}>{showDefault[0].group}</span>
            )}
            <span className={classes.chosedSelectionProjectTitle}>{showDefault[0].label}</span>
          </span>
        )}
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='#1d1e1cb3' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' aria-label='Down chevron icon' className='pds-flex-no-shrink' aria-hidden='true'>
          <polyline points='5 9 12 17 19 9' />
        </svg>
      </Button>
      <Modal
        open={openProjectBtnModal}
        onClose={handleCloseProjectBtnModal}
        BackdropProps={{ className: classes.customOverlay }}
      >
        <Grid className={classes.inputSearchItens} style={{ top: topPosition }}>
          <Select
            defaultValue={{ value: 'search', label: 'Search...' }}
            options={options}
            formatGroupLabel={formatGroupLabel}
            isSearchable
            menuIsOpen
            styles={customStyles}
            onChange={handleSelectChange}
          />
        </Grid>
      </Modal>
    </>
  )
}
